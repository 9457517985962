let header = document.querySelector('header#header')
let content = document.querySelector('main#content')
content.style.paddingTop = header.height + 'px'

let systemMessage = document.querySelector('#system-alert')
if (systemMessage) {
	setTimeout(() => {
		systemMessage.style.opacity = '0'
		setTimeout(() => {
			systemMessage.style.visibility = 'hidden'
		}, 500)
	}, 2000)
}

const buttons = document.querySelectorAll('[data-role="accordion"] button')

if (buttons) {
	buttons.forEach((button) => {
		button.addEventListener('click', () => {
			buttons.forEach((button) => {
				const answer = button.nextElementSibling
				button.classList.remove('border-b')
				answer.classList.add('scale-y-0')
				answer.classList.add('h-0')
				answer.classList.add('py-0')
				button.querySelector('img').classList.remove('rotate-180')
			})
			const answer = button.nextElementSibling
			button.classList.toggle('border-b')
			answer.classList.toggle('scale-y-0')
			answer.classList.toggle('h-0')
			answer.classList.toggle('py-0')
			button.querySelector('img').classList.toggle('rotate-180')
		})
	})
}

let options = document.querySelectorAll('[data-role="option"]')
options.forEach((element) => {
	element.addEventListener('change', (event) => {
		options.forEach((optElem) => {
			let parent = optElem.parentElement
			parent.classList.remove('bg-black')
			parent.classList.remove('text-white')
			parent.classList.remove('border-black')
			let cloneRadio = parent.querySelector('label')
			cloneRadio.classList.remove('before:bg-white')
			cloneRadio.classList.remove('border-white')
			cloneRadio.classList.add('before:opacity-0')
		})
		let parentElement = element.parentElement

		parentElement.classList.add('bg-black')
		parentElement.classList.add('text-white')
		parentElement.classList.add('border-black')
		let cloneRadio = parentElement.querySelector('label')
		cloneRadio.classList.add('before:bg-white')
		cloneRadio.classList.add('border-white')
		cloneRadio.classList.remove('before:opacity-0')
	})
})

let typeRadios = document.querySelectorAll('[name="type"]')
typeRadios.forEach((element) => {
	element.addEventListener('change', (event) => {
		typeRadios.forEach((xElem) => {
			let parentElement = xElem.parentElement
			parentElement
				.querySelector('label#first-label')
				.classList.remove('before:bg-black')
			parentElement
				.querySelector('label#first-label')
				.classList.add('before:bg-white')
		})
		let parentElement = element.parentElement
		parentElement
			.querySelector('label#first-label')
			.classList.add('before:bg-black')
		parentElement
			.querySelector('label#first-label')
			.classList.remove('before:bg-white')
	})
})

let packages = document.querySelectorAll('[name="package"]')
packages.forEach((element) => {
	element.addEventListener('change', (e) => {
		packages.forEach((xElem) => {
			let parentElement = xElem.parentElement
			parentElement
				.querySelector('label')
				.classList.remove('before:bg-black')
			parentElement.querySelector('label').classList.add('before:bg-white')
		})
		let parentElement = element.parentElement
		parentElement.querySelector('label').classList.add('before:bg-black')
		parentElement.querySelector('label').classList.remove('before:bg-white')
	})
})

let openMenuButton = document.querySelector('[data-role="open-menu"]')
let menuContext = document.querySelector('[data-role="menu-context"]')
openMenuButton.addEventListener('click', (e) => {
	document.body.classList.toggle('overflow-hidden')
	menuContext.classList.toggle('translate-x-full')
})
