const elements = document.querySelectorAll('[data-src]')
const scrollObject = document.querySelector('body')
elements.forEach((element) => {
	const callback = (entries) => {
		const entrie = entries[0]
		if (entrie.isIntersecting) {
			entrie.target.src = entrie.target.dataset.src
		}
		console.log(entrie)
	}

	const options = {
		root: scrollObject,
		rootMargin: '-100px'
	}

	const observer = new IntersectionObserver(callback, options)
	observer.observe(element)
})
